import { AbstractControl } from '@angular/forms';

export class CustomValidator {
    static validateEmail(): ((c: AbstractControl) => any) {
        return (c) => {
            const p = c.value;
            if (!p) {
                return {
                    email_missing: true
                };
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(p).toLowerCase())) {
                return {
                    email_pattern: true
                };
            }
        };
    }

    static validateUsername(): ((c: AbstractControl) => any) {
        return (c) => {
            const p = c.value;
            if (!p) {
                return {
                    username_missing: true
                };
            }
            if (!new RegExp(/^[0-9a-zA-Z_]+$/g).test(p)) {
                return {
                    username_pattern: true
                };
            }
        };
    }

    static validatedPassword(): ((c: AbstractControl) => any) {
        return (c) => {
            const p = c.value;
            if (!p) {
                return {
                    password_missing: true
                };
            }
            if (p.length < 8 || !new RegExp(/[a-z]+/g).test(p) ||
                !new RegExp(/[A-Z]+/g).test(p) ||
                !new RegExp(/[0-9]+/g).test(p)) {
                return {
                    password_pattern: true
                };
            }
        };
    }

    static validatedConfirmPassword(): ((c: AbstractControl) => any) {
        return (c) => {
            if (!c.parent) {
                return null;
            }
            const p = c.value;
            if (!p) {
                return {
                    password_missing: true
                };
            }
            if (c.parent.controls['password'].value !== c.value) {
                return {
                    password_not_match: true
                };
            }
        };
    }
}
